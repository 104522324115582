"use client";

import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { CancelButton, ConfirmButton } from "@src/components";
import Icons from "@src/components/Icons";
import { formatNumberCurrency } from "@src/utils/common";
import CartVoucherModel from "@src/models/cartVoucher";
const IconVoucher = {
  freeship: {
    title: "Vận chuyển",
    icon: "free-ship"
  },
  percentage: {
    title: "Discount",
    icon: "discount"
  },
  amount: {
    title: "Đơn hàng",
    icon: "voucher"
  }
};
const WrapperItem = styled(Stack)({
  minWidth: "290px",
  alignItems: "center",
  height: "fit-content",
  "& img.voucher-background": {
    height: "auto !important",
    zIndex: 1,
    maxWidth: 340,
    position: "relative",
    top: "4px"
  }
});
const VoucherItem = (props: {
  type: "select" | "card";
  onSaleColor: string | null;
  data: CartVoucherModel;
  loading?: boolean;
  isSelected?: boolean;
  onAddVoucher: (voucher: CartVoucherModel) => void;
}) => {
  const {
    type,
    onSaleColor,
    data: {
      name,
      min_order_value,
      max_discount_amount,
      num_of_days_left,
      discount_type,
      voucher_type,
      saved,
      disabled,
      disabled_reason
    },
    loading,
    data,
    isSelected,
    onAddVoucher
  } = props;
  const voucherTypeIcon = voucher_type?.toLowerCase() === "freeship" ? "freeship" : discount_type;
  const [selected, setSelected] = useState<boolean>(isSelected);
  const handleToggle = () => {
    setSelected(prev => !prev);
  };
  useEffect(() => {
    setSelected(isSelected);
  }, [isSelected]);
  const getVoucherMinOrderTitle = () => {
    return `Đơn hàng từ ${formatNumberCurrency(Math.floor(min_order_value / 1000))}k`;
  };
  const getVoucherDiscountTitle = () => {
    if (!max_discount_amount) return "Giảm giá không giới hạn";
    return `Giảm tối đa ${formatNumberCurrency(Math.floor(max_discount_amount / 1000))}k`;
  };
  return <Box position="relative" sx={{
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "16px",
    justifyContent: "space-between"
  }} data-sentry-element="Box" data-sentry-component="VoucherItem" data-sentry-source-file="index.tsx">
      <WrapperItem data-sentry-element="WrapperItem" data-sentry-source-file="index.tsx">
        <img src="/assets/images/voucher.svg" alt="voucher background" className="voucher-background" />
        <Stack sx={{
        zIndex: 2,
        gap: "8px",
        position: "absolute",
        padding: "20px"
      }} width="100%" alignItems="center" justifyContent="space-between" data-sentry-element="Stack" data-sentry-source-file="index.tsx">
          <Stack direction="column" spacing="1px" data-sentry-element="Stack" data-sentry-source-file="index.tsx">
            <Typography sx={{
            fontSize: 14,
            fontWeight: 600,
            color: onSaleColor || "#FF5F67"
          }} className="truncate-2" data-sentry-element="Typography" data-sentry-source-file="index.tsx">
              {name}
            </Typography>
            <Typography variant="body2" component="span" sx={{
            color: "#282828",
            fontSize: "10px"
          }} data-sentry-element="Typography" data-sentry-source-file="index.tsx">
              {getVoucherMinOrderTitle()}
            </Typography>
            <Typography variant="body2" component="span" sx={{
            color: "#282828",
            fontSize: "10px"
          }} data-sentry-element="Typography" data-sentry-source-file="index.tsx">
              {getVoucherDiscountTitle()}
            </Typography>
            <Typography variant="body2" component="span" color="#686868" sx={{
            fontSize: "10px",
            fontStyle: "italic"
          }} data-sentry-element="Typography" data-sentry-source-file="index.tsx">
              {`Còn lại: ${num_of_days_left} ngày`}
            </Typography>
            {type === "select" && disabled && <Typography variant="body2" component="span" color="#F46647" sx={{
            fontSize: "10px"
          }}>
                {disabled_reason}
              </Typography>}
          </Stack>
          {type === "card" && <Stack height="100%" alignItems="center" justifyContent="center" spacing="12px">
              <svg xmlns="http://www.w3.org/2000/svg" width="2" height="95" viewBox="0 0 2 95" fill="none">
                <path d="M0.539062 0.621094L0.539067 93.6532" stroke="#FF5F67" stroke-width="1.05718" stroke-linecap="round" stroke-dasharray="4.23 4.23" />
              </svg>
              <Stack direction="column" alignItems="center" justifyContent="center" spacing="4px" sx={{
            "& svg": {
              color: onSaleColor || "#FF5F67"
            }
          }}>
                <Icons name={IconVoucher[voucherTypeIcon].icon} />
                <Stack width="100%" justifyContent="flex-end" mt={1} sx={{
              "& span": {
                fontSize: "10px !important"
              }
            }}>
                  {saved ? <CancelButton sx={{
                border: "1.5px solid #FF5F67 !important",
                padding: "8px 12px",
                height: "30px !important",
                minHeight: "30px !important",
                borderRadius: "32px !important",
                textTransform: "none",
                pointerEvent: "none",
                cursor: "not-allowed",
                color: "#FF5F67 !important",
                background: "#FFF0F1 !important",
                opacity: "1 !important"
              }} disabled label="Đã lưu" /> : <ConfirmButton sx={{
                background: "#FF5F67",
                color: "white !important",
                padding: "8px 12px",
                height: "30px !important",
                minHeight: "30px !important",
                borderRadius: "32px !important",
                textTransform: "none",
                fontSize: "10px !important"
              }} loading={loading} label="Lưu ngay" onClick={() => onAddVoucher(data)} />}
                </Stack>
              </Stack>
            </Stack>}
        </Stack>
      </WrapperItem>
      {type === "select" && !disabled && <Box sx={{
      cursor: "pointer",
      marginRight: {
        xs: "0px",
        md: "24px"
      }
    }} role="button" onClick={handleToggle}>
          <Icons sx={{
        padding: 0
      }} onClick={() => onAddVoucher(data)} name={selected ? "radio-checked" : "radio"} />
        </Box>}
    </Box>;
};
export default VoucherItem;